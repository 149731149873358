import {
  always,
  both,
  complement,
  compose,
  curry,
  either,
  groupBy,
  ifElse,
  indexBy,
  isEmpty,
  isNil,
  map,
  path,
  prop,
  propEq,
  range,
  tail,
  uniq,
} from "ramda";
import {
  memoizeLatest,
  memoizeLatestBy,
  notEmpty,
  notNil,
  parseUrl,
} from "utils";
import { filterMap, mapDelete, toValuesArray } from "utils/map";
import {
  setDifference,
  setIntersection,
  setIsNotEmpty,
  setToArray,
} from "utils/set";

export const DISPLAY_USERS_MAX_SYSTEMS = 1000;

/**
 * See the values that will be applied to each system on save. Use this for debugging when you need to see which values will apply to which panels.
 * @example //In AdminUsers > UserForm.js
 *   console.log(viewChangesToSend(selectedSystemIds, systems, state, resource));
 */
export const viewChangesToSend = (
  selectedSystemIds,
  systems,
  state,
  resource
) => {
  return selectedSystemIds.map((systemId) => {
    const system = systems.get(systemId);

    const isXf = system.get("isXf");
    const isXR = system.get("isXR");
    const isX1 = system.get("isX1");
    const isTakeoverPanel = system.get("isTakeoverPanel");
    const isTMSentry = system.get("isTmSentry");

    //All the created user codes
    const userCodes = toValuesArray(state.credentialsById);

    //Predicate to be used with map filtering
    const systemIds = (arg) => (id) => id === arg;

    //The profiles that are currently selected as typeof map
    const selectedProfiles = toValuesArray(
      filterMap(systemIds, state.profilesBySystemId)
    ); //Get the profile ids and convert the map to an array of values

    const profileIds = selectedProfiles.flatMap((val) => setToArray(val)); //Convert selectedProfiles, which is an array of sets, into a flat array of values

    //The applied user codes by the selected system id as the entire userCodes object
    const rawApplicableUserCodes =
      userCodes.filter((item) => item.changedUserCodePanelIds.has(systemId)) ??
      [];

    /**The applicable code types by system id. This is the same logic that is used to filter types in `resources > createUserCode() > user_codes`*/
    const codeIsApplicable = (type) => {
      const isCode = type === CodeType.CODE;
      const isBadge = type === CodeType.CARD;
      const isMobile = type === CodeType.MOBILE;
      const isDigitalCard = type === CodeType.DIGITAL_CARD;
      // X1 systems can only receive "BADGE" or "MOBILE" credentials
      if (isX1) {
        return isBadge || isMobile || isDigitalCard;
      }
      // Takeover panels can't receive MOBILE type credentials
      else if (isTakeoverPanel || isTMSentry) {
        return !isMobile && !isDigitalCard;
      }
      //XF panels can only have codes
      else if (isXf) {
        return isCode;
      }
      //XR and XT
      else {
        return type;
      }
    };

    //The applied user codes by the selected system id as a partial object
    const applicableUserCodes = rawApplicableUserCodes.map((code) => {
      if (codeIsApplicable(code.type)) {
        return {
          type: code.type ?? "",
          code: code.code ?? "",
          level: !isXR ? code.userLevel : "N/A",
          armOnly: !isXf && !isXR ? code.armOnly : "N/A",
          temp: !isXR ? code.temporary : "N/A",
        };
      } else {
        return [];
      }
    });

    //All of a selected system's profiles
    const profiles = resource.customer.controlSystem(systemId).profiles;

    //The applied profiles by the selected system id
    const applicableProfiles = profileIds
      .map((id) =>
        profiles.find((profile) => String(profile.id) === String(id))
      )
      .filter((existingItem) => existingItem);

    const userCodeBySystem = {
      systemId: systemId,
      userFirstName: state.firstName,
      userLastName: state.lastName,
      userEmail: state.emailAddress,
      userPhoneNumber: state.phoneNumber,
      userPhoto: state.userPhoto,
      userNotes: state.notes,
      userCodes: applicableUserCodes,
      profiles: applicableProfiles,
    };

    return userCodeBySystem;
  });
};

export const CodeType = {
  CODE: "PIN",
  CARD: "BADGE",
  MOBILE: "MOBILE",
  DIGITAL_CARD: "DIGITAL_CARD",
  UNKNOWN: "UNKNOWN",
};

export const UserLevel = {
  MASTER: "MASTER",
  STANDARD: "STANDARD",
};

export const AppUserLevel = {
  ADMIN: "ADMIN",
  STANDARD: "STANDARD",
  ACCESS_ONLY: "ACCESS_ONLY",
};

export const AppUserLevelValue = {
  ADMIN: "admin",
  STANDARD: null,
  ACCESS_ONLY: "access_only",
};

export const originalSystemIds = compose(
  memoizeLatest(
    (originalPerson) =>
      new Set(
        originalPerson?.credentials
          .flatMap(prop("userCodes"))
          .map(path(["controlSystem", "id"])) ?? []
      )
  ),
  prop("originalPerson")
);

export const getProfilesBySystemIdFromLinkedProfilesById = memoizeLatest(
  (resource, linkedProfileIds) =>
    groupBy(
      path(["controlSystem", "id"]),
      [...linkedProfileIds]
        .map(resource.customer.linkedProfile)
        .flatMap(({ profiles }) => profiles)
    )
);
export const getProfilesBySystemIdFromSiteGroupsById = memoizeLatest(
  (resource, siteGroupIds) => {
    return [...siteGroupIds]
      .map((id) => resource.customer.linkedProfile(id))
      .flatMap(({ profiles }) => profiles)
      .reduce((profilesBySystemId, profile) => {
        return {
          ...profilesBySystemId,
          [profile.panelId]: [
            ...(profilesBySystemId[profile.panelId]
              ? profilesBySystemId[profile.panelId]
              : []),
            profile,
          ],
        };
      }, {});
  }
);
export const getSystemIdsFromLinkedProfilesById = compose(
  memoizeLatest(compose(map(Number), Object.keys)),
  getProfilesBySystemIdFromLinkedProfilesById
);
export const getSystemIdsFromSiteGroupsById = compose(
  memoizeLatest(compose(map(Number), Object.keys)),
  getProfilesBySystemIdFromSiteGroupsById
);
export const getSystemIdsFromSiteGroupIds = (resource, siteGroupIds) =>
  uniq(
    [...siteGroupIds]
      .map(compose(prop("siteId"), resource.customer.linkedProfile))
      .map((siteId) =>
        resource.customer.allMyControlSystems.filter(propEq("siteId", siteId))
      )
      .flat()
      .map(prop("id"))
  );

export const getApplicableSystemIdsFromState = memoizeLatest(
  (state, resource) =>
    new Set(
      state.credentialsSelectionType === "PROFILES"
        ? Object.keys({
            ...getProfilesBySystemIdFromLinkedProfilesById(resource, [
              ...state.linkedProfileIds,
            ]),
            ...getProfilesBySystemIdFromSiteGroupsById(resource, [
              ...state.siteGroupIds,
            ]),
          }).map(Number)
        : [
            ...Object.keys(
              getProfilesBySystemIdFromSiteGroupsById(resource, [
                ...state.siteGroupIds,
              ])
            ).map(Number),
            ...state.areasBySystemId.keys(),
            ...state.profilesBySystemId.keys(),
            ...state.selectedNonAreaXtSystemIds,
            ...state.selectedXfSystems,
          ]
    )
);

export const getApplicableSystemsFromState = memoizeLatest((state, resource) =>
  [...getApplicableSystemIdsFromState(state, resource)]
    .map(resource.customer.controlSystem)
    .filter(Boolean)
);

export const getApplicableXtSystemsFromState = memoizeLatest(
  (state, resource) =>
    getApplicableSystemsFromState(state, resource).filter(
      (system) => system.isXt
    )
);

/** We need this function because takeover panels like DualComs are based on XT firmware, therefore report both `isXt` and `isTakeoverPanel` as true. This function allows us to sort out the true XT panels like XT30 and XT50 from the XT-based takeover panel models/. */
export const getApplicableNonTakeoverXtSystemsFromState = memoizeLatest(
  (state, resource) =>
    getApplicableSystemsFromState(state, resource).filter(
      (system) => system.isXt && !system.isTMSentry && !system.isTakeoverPanel
    )
);

export const getApplicableTMSentrySystemsFromState = memoizeLatest(
  (state, resource) =>
    getApplicableSystemsFromState(state, resource).filter(
      (system) => system.isTMSentry
    )
);

export const getApplicableTakeoverPanelsFromState = memoizeLatest(
  (state, resource) =>
    getApplicableSystemsFromState(state, resource).filter(
      (system) => system.isTakeoverPanel
    )
);

export const getApplicableXrSystemsFromState = memoizeLatest(
  (state, resource) =>
    getApplicableSystemsFromState(state, resource).filter(
      (system) => system.isXr
    )
);

export const getApplicableXfSystemsFromState = memoizeLatest(
  (state, resource) =>
    getApplicableSystemsFromState(state, resource).filter(
      (system) => system.isXf
    )
);

export const getApplicableXt75SystemsFromState = memoizeLatest(
  (state, resource) =>
    getApplicableSystemsFromState(state, resource).filter(
      (system) => system.isXT75
    )
);

export const getApplicableX1SystemsFromState = memoizeLatest(
  (state, resource) =>
    getApplicableSystemsFromState(state, resource).filter(
      (system) => system.isX1
    )
);

export const fullName = ({ firstName, lastName }) =>
  `${firstName} ${lastName}`.trim();

export const numberRangeForAllApplicableSystems = memoizeLatest(
  (resource, state) =>
    getApplicableSystemsFromState(state, resource).reduce(
      ([min, max], controlSystem) => [
        Math.max(min, controlSystem.minUserCodeNumber),
        Math.min(max, controlSystem.maxUserCodeNumber),
      ],
      [0, Infinity]
    )
);

export const getCredentialsFromState = memoizeLatest((state) =>
  toValuesArray(state.credentialsById)
);

export const getCodeCredentialsFromState = memoizeLatest((state) =>
  getCredentialsFromState(state).filter(propEq("type", CodeType.CODE))
);

export const hasTwoOrMoreCodeCredentials = memoizeLatest(
  (state) => getCodeCredentialsFromState(state).length >= 2
);

export const firstCodeCredentialFromState = memoizeLatest(
  (state) => getCodeCredentialsFromState(state)[0]
);

export const availableNumbersForAllApplicableSystems = memoizeLatest(
  (resource, state, credentialId) => {
    const systems = getApplicableSystemsFromState(state, resource);
    if (isEmpty(systems)) {
      return new Set();
    }
    const currentCredentialUserNumber =
      state.credentialsById.get(credentialId)?.commonUserCodeNumber;

    const [min, max] = numberRangeForAllApplicableSystems(resource, state);

    const validNumbers = new Set(range(min, max + 1));
    const availableNumbers = systems
      .map((system) => new Set(system.availableUserCodeNumbers))
      .reduce(setIntersection, validNumbers);
    if (
      currentCredentialUserNumber &&
      validNumbers.has(currentCredentialUserNumber)
    ) {
      availableNumbers.add(currentCredentialUserNumber);
    }

    return availableNumbers;
  }
);

export const availableNumbersForCredential = memoizeLatestBy(
  (_, __, credentialId) => credentialId,
  (resource, state, credentialId) => {
    const availableNumbers = availableNumbersForAllApplicableSystems(
      resource,
      state
    );
    const newCredentialNumbers = new Set(
      toValuesArray(mapDelete(credentialId, state.credentialsById))
        .filter(({ numberForNewUserCodes }) => notNil(numberForNewUserCodes))
        .map(prop("numberForNewUserCodes"))
    );

    return setDifference(newCredentialNumbers, availableNumbers);
  }
);

export const numberIsAvailableForCredential = curry(
  (resource, state, credentialId, number) =>
    availableNumbersForAllApplicableSystems(resource, state, credentialId).has(
      number
    )
);

export const numberOutOfRangeForApplicableSystems = curry(
  (resource, state, number) => {
    const [minNumber, maxNumber] = numberRangeForAllApplicableSystems(
      resource,
      state
    );
    return number < minNumber || number > maxNumber;
  }
);

export const userCodeNumberForSystem = (system, credential) =>
  credential.originalUserCodesByPanelId.get(system.id)?.number ??
  credential.numberForNewUserCodes ??
  system.firstAvailableUserCodeNumber;

export const getPanelsWithSendCodesToLocks = memoizeLatestBy(
  (_, __, credential) => credential.id,
  (state, resource, credential) =>
    getApplicableSystemsFromState(state, resource).filter(
      (system) =>
        system.supportsSendCodesToLocks &&
        userCodeNumberForSystem(system, credential) >=
          system.sendCodesToLocksMinUserCodeNumber &&
        userCodeNumberForSystem(system, credential) <=
          system.sendCodesToLocksMaxUserCodeNumber
    )
);

export const applicableSystemsSupportInactiveUser = (state, resource) =>
  getApplicableSystemsFromState(state, resource).some(
    prop("supportsInactiveUser")
  );

export const hasUserCodesToBeCreated = memoizeLatest((state, resource) =>
  [...getApplicableSystemIdsFromState(state, resource)].some(
    (systemId) => !originalSystemIds(state).has(systemId)
  )
);

export const hasSystemsWithStoredUserCodes = (state, resource) =>
  getApplicableSystemsFromState(state, resource).some(prop("storeUserCodes"));

export const codeIsRequiredForCredential = (state, resource, credential) =>
  credential.isNew ||
  (hasUserCodesToBeCreated(state, resource) &&
    !hasSystemsWithStoredUserCodes(state, resource));

export const jobIsPending = (job) =>
  ["new", "running", "acquired", "started"].includes(job.status);

export const jobSucceeded = (job) => ["SUCCESS"].includes(job.status);

export const jobFailed = (job) => ["FAIL"].includes(job.status);

export const hasPendingJobs = memoizeLatest((jobGroup) =>
  jobGroup.jobs.some(jobIsPending)
);

export const jobGroupIsPending = (job) =>
  ["new", "running", "acquired", "started"].includes(job?.status);

export const jobGroupSucceeded = (job) => ["SUCCESS"].includes(job?.status);

export const jobGroupFailed = (job) =>
  ["FAIL", "ABORT", "DELAYED", "HIJACKED", "UNKNOWN"].includes(job?.status);

export const jobGroupHasFailedJobs = (jobGroup) =>
  jobGroup.jobs.some(jobFailed);

export const isNotFullUserCodeJob = (job) =>
  job.errors
    .map((errors) => !/is not a valid value \[0/i.test(errors[0]))
    .getOrElse(true);

export const getFailedJobs = (jobGroup) =>
  jobGroup?.jobs.filter(jobFailed) ?? [];

export const getFailedJobsWithUsableUserCodes = (jobGroup) =>
  getFailedJobs(jobGroup).filter(isNotFullUserCodeJob);

const jobGroupHasOperation = curry(
  (operation, jobGroup) =>
    jobGroup?.originalRequest?.operation?.toUpperCase() === operation
);

export const isAddJobGroup = jobGroupHasOperation("ADD");
export const isUpdateJobGroup = jobGroupHasOperation("UPDATE");
export const isDeleteJobGroup = either(
  jobGroupHasOperation("DELETE"),
  (jobGroup) =>
    !jobGroup?.originalRequest?.id &&
    jobGroup?.originalRequest?.credentials[0]?.operation?.toUpperCase() ===
      "DELETE"
);

export const jobsByCredentialId = ifElse(
  isNil,
  always({}),
  compose(groupBy(path(["userCode", "credentialId"])), prop("jobs"))
);

export const indexByPanelId = indexBy(prop("panelId"));

export const credentialUserCodes = (credential, jobGroup, resource) => {
  return credential.userCodes
    .map((userCode) => {
      const panelId = userCode.controlSystem.id;
      const job = jobGroup?.jobs.find(
        (job) =>
          job.userCode.credentialId === credential.id &&
          job.userCode.panelId === panelId
      );
      return job && !resource.isDeleteJob(job) && isNotFullUserCodeJob(job)
        ? {
            ...userCode,
            ...job.userCode,
            number: job.userCode.number ?? userCode.number,
          }
        : userCode;
    })
    .concat(
      jobGroup?.jobs
        .filter(resource.isAddJob)
        .filter(isNotFullUserCodeJob)
        .map(({ userCode }) => ({ ...userCode, isNew: true })) ?? []
    );
};

export const initialPersonFromCredential = (credential) => {
  const nameParts = credential.userCodes[0]?.name?.split(" ") ?? [];
  return {
    id: null,
    firstName: nameParts[0] ?? "",
    lastName: tail(nameParts).join(" ") ?? "",
    emailAddress: "",
    notes: "",
    photoUrl: null,
    user: null,
    credentials: [credential],
  };
};

export const myUserHasPanelPermission = curry(
  (permission, panelId, myUser) =>
    !!myUser.getIn(["userPermissions", panelId.toString(), permission])
);

export const selectedProfiles = memoizeLatest((state, resource) =>
  setIsNotEmpty(state.linkedProfileIds) || setIsNotEmpty(state.siteGroupIds)
    ? [...state.linkedProfileIds, ...state.siteGroupIds]
        .map(resource.customer.linkedProfile)
        .flatMap(prop("profiles"))
    : [...state.profilesBySystemId.values()].flatMap((profileIds) =>
        [...profileIds].map(resource.customer.profile)
      )
);

export const getAllLinkedProfilesForPerson = memoizeLatest((person) => [
  ...person.credentials
    .flatMap(prop("linkedProfiles"))
    .reduce(
      (acc, linkedProfile) => acc.set(linkedProfile.id, linkedProfile),
      new Map()
    )
    .values(),
]);

export const getAllNonX1LinkedProfilesForPerson = memoizeLatest((person) =>
  getAllLinkedProfilesForPerson(person).filter(({ siteId }) => !siteId)
);

export const getAllX1LinkedProfilesForPerson = memoizeLatest((person) =>
  getAllLinkedProfilesForPerson(person).filter(({ siteId }) => !!siteId)
);

const originalProfiles = memoizeLatest((state) => {
  const originalCredentials = [...state.credentialsById.values()]
    .map(prop("originalCredential"))
    .filter(notNil);

  if (isEmpty(originalCredentials)) {
    return [];
  }

  const linkedProfiles = getAllLinkedProfilesForPerson({
    credentials: originalCredentials,
  });
  return notEmpty(linkedProfiles)
    ? linkedProfiles.flatMap(prop("profiles"))
    : originalCredentials.flatMap(prop("userCodes")).flatMap(prop("profiles"));
});

export const originalProfileIds = compose(
  memoizeLatest((profiles) => new Set(profiles.map(prop("id")))),
  originalProfiles
);

export const unlinkedControlSystemProfiles = memoizeLatest((controlSystem) =>
  controlSystem.profiles.filter(({ linkedProfile }) => !linkedProfile)
);

export const linkedControlSystemProfiles = memoizeLatest((controlSystem) =>
  controlSystem.profiles.filter(({ linkedProfile }) => linkedProfile)
);

export const userHasCardPlusPin = (state, resource) =>
  selectedProfiles(state, resource).some(prop("cardPlusPin"));

export const userProfileWithCardPlusPin = (state, resource) =>
  selectedProfiles(state, resource).find(prop("cardPlusPin"));

export const hasAddedProfileWithCardPlusPin = memoizeLatest(
  (state, resource) => {
    const profileWithCardPlusPin = userProfileWithCardPlusPin(state, resource);
    return (
      !!profileWithCardPlusPin &&
      !originalProfileIds(state).has(profileWithCardPlusPin.id)
    );
  }
);

export const isStandardUser = (user) =>
  (user?.role === AppUserLevel.STANDARD) | AppUserLevel.ACCESS_ONLY;
export const isAdminUser = (user) => user?.role === AppUserLevel.ADMIN;

export const hasXtSystemsSelected = (state, resource) =>
  !isEmpty(getApplicableXtSystemsFromState(state, resource));

export const hasNonTakeoverXtSystemsSelected = (state, resource) =>
  !isEmpty(getApplicableNonTakeoverXtSystemsFromState(state, resource));

export const hasXrSystemsSelected = (state, resource) =>
  !isEmpty(getApplicableXrSystemsFromState(state, resource));

export const hasXfSystemsSelected = (state, resource) =>
  !isEmpty(getApplicableXfSystemsFromState(state, resource));

export const hasTMSentrySystemsSelected = (state, resource) =>
  !isEmpty(getApplicableTMSentrySystemsFromState(state, resource));

export const hasTakeoverPanelsSelected = (state, resource) =>
  !isEmpty(getApplicableTakeoverPanelsFromState(state, resource));

export const hasXt75SystemsSelected = (state, resource) =>
  !isEmpty(getApplicableXt75SystemsFromState(state, resource));

export const hasX1SystemsSelected = (state, resource) =>
  !isEmpty(getApplicableX1SystemsFromState(state, resource));

export const hasXrAndXtSystemsSelected = both(
  hasXtSystemsSelected,
  hasXrSystemsSelected
);

export const hasOnlyXfSystemsSelected = (state, resource) =>
  !isEmpty(getApplicableXfSystemsFromState(state, resource)) &&
  !hasXrSystemsSelected(state, resource) &&
  !hasXtSystemsSelected(state, resource) &&
  !hasXt75SystemsSelected(state, resource) &&
  !hasX1SystemsSelected(state, resource) &&
  !hasTMSentrySystemsSelected(state, resource);

export const hasOnlyXtAndXFSystemsSelected = (state, resource) =>
  (hasXtSystemsSelected(state, resource) ||
    hasXfSystemsSelected(state, resource)) &&
  !hasXrSystemsSelected(state, resource) &&
  !hasXt75SystemsSelected(state, resource) &&
  !hasX1SystemsSelected(state, resource) &&
  !hasTMSentrySystemsSelected(state, resource);

export const hasOnlyTakeoverPanelsSelected = (state, resource) =>
  (hasTakeoverPanelsSelected(state, resource) ||
    hasTMSentrySystemsSelected(state, resource)) &&
  !hasXrSystemsSelected(state, resource) &&
  !hasXt75SystemsSelected(state, resource) &&
  !hasX1SystemsSelected(state, resource) &&
  !hasNonTakeoverXtSystemsSelected(state, resource);

export const hasOutdatedPanels = (resource) =>
  resource.customer.allControlSystems.some(
    (system) => system.isXr && system.hasLegacyTempUser
  );

export const parseSearchParams = (locationSearch) =>
  parseUrl(locationSearch).params;

export const isX1 = (system) => system.isX1;
export const isNotX1 = complement(isX1);
