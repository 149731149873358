/**
 *
 * Area Model
 * @author Kyle Ellman, Chad Watson
 *
 */

import graphql from "babel-plugin-relay/macro";
import Immutable, { OrderedSet, Record, Seq } from "immutable";
import { applySpec, compose, prop, __ } from "ramda";
import { readInlineData } from "react-relay";
import { ArmedStatusEnum } from "securecom-graphql/client";
import { toInt } from "utils";
import { boolToYesNo, yesNoToBool } from "utils/models";

const PRIORITIES = {
  PERIMETER: 1,
  INTERIOR: 2,
  BEDROOMS: 3,
};

/**
 * Builds an Area model
 * @param  {object} json response from areas API
 * @return {object}
 */
export default applySpec({
  name: prop("name"),
  number: compose(toInt, prop("number")),
  autoArm: compose(yesNoToBool, prop("auto_arm")),
  autoDisarm: compose(yesNoToBool, prop("auto_disrm")),
  priority: compose(prop(__, PRIORITIES), prop("name")),
});

export const toApiObject = applySpec({
  name: prop("name"),
  number: prop("number"),
  auto_arm: compose(boolToYesNo, prop("autoArm")),
  auto_disrm: compose(boolToYesNo, prop("autoDisarm")),
});

export const createAreasState = Record({
  byNumber: OrderedSet(),
  requestingAll: false,
  refreshing: false,
  requestError: false,
});

/**
 * @param { import("./__generated__/areaModel_areaConnection.graphql").areaModel_areaConnection$key } key
 * @returns {ReturnType<createAreasState>}
 */
export const convertGqlDataToReduxData = (key) => {
  const data = readInlineData(
    graphql`
      fragment areaModel_areaConnection on AreaConnection @inline {
        edges {
          node {
            id
            name
            armedStatus
            isInAlarm
            number
            isArmable
            isDisarmable
            badZonesOption
            autoArmEnabled
            autoDisarmEnabled
          }
        }
      }
    `,
    key
  );
  return createAreasState({
    byNumber: Seq(data.edges)
      .map(({ node }) =>
        Immutable.Collection({
          name: node.name,
          number: node.number,
          armed: node.armedStatus === ArmedStatusEnum.ARMED,
          armedStatus: node.armedStatus,
          burgAlarm: node.isInAlarm,
          armable: node.isArmable,
          disarmable: node.isDisarmable,
        })
      )
      .toOrderedSet(),
    refreshing: false,
    requestError: false,
    requestingAll: false,
  });
};
