/**
 *
 * System Options Model
 * @author Kyle Ellman
 *
 */

import Maybe from "data.maybe";
import {
  applySpec,
  compose,
  equals,
  has,
  isNil,
  map,
  prop,
  toUpper,
  unless,
} from "ramda";
import { boolToYesNo, yesNoToBool } from "utils/models";

/**
 * Builds the system options model
 * @param  {object} json response from system options API
 * @return {object}
 */
export default applySpec({
  armDisarmActivityDays: compose(
    map(parseInt),
    Maybe.fromNullable,
    prop("ad_act_day")
  ),
  zoneActivityDays: compose(
    map(parseInt),
    Maybe.fromNullable,
    prop("zn_act_hrs")
  ),
  closingCheck: compose(yesNoToBool, prop("close_chk")),
  hasClosingCheckCapability: has("close_chk"), //This key won't be returned by the system options API for panels that don't support closing check like (e.g. DualCom and TM Sentry)
  isECP: compose(equals("E"), unless(isNil, toUpper), prop("kpad_input")),
  isDSC: compose(equals("D"), unless(isNil, toUpper), prop("kpad_input")),
});

export const toApiObject = applySpec({
  close_chk: compose(boolToYesNo, prop("closingCheck")),
});
