import noop from "common/utils/universal/noop";
import { getGQLUrl } from "common/utils/universal/securecom-urls";
import * as React from "react";
import { RelayEnvironmentProvider as DefaultRelayEnvironmentProvider } from "react-relay/hooks";
import { Environment, Network, RecordSource, Store } from "relay-runtime";
import { dispatchLogOut } from "resources/utils/json";
import { selectAuthToken } from "store/auth/selectors";
import { selectActiveSystem } from "store/systems/selectors";
import store from "./store";

const graphqlFetchFunction = async (operation, variables, options) => {
  const state = store.getState();
  const authToken = options.authToken || selectAuthToken(state);

  return await fetch(
    process.env.REACT_APP_GRAPHQL_ENDPOINT || getGQLUrl(options.env),
    {
      method: "POST",
      body: JSON.stringify({
        query: operation.text,
        variables,
      }),
      headers: JSON.parse(
        JSON.stringify({
          env: options.env,
          "auth-token": authToken,
          "user-code": options.activeSystem?.userCode,
          "staging-branch": process.env.REACT_APP_CLIENT_STAGING_BRANCH,
          "content-type": "application/json",
          "Accept-Encoding": "gzip",
          "apollographql-client-name": "Virtual Keypad Web",
          "apollographql-client-version": process.env.REACT_APP_VERSION,
        })
      ),
    }
  ).then((response) => response.json());
};

const createFetchRelay = () => {
  return async function fetchRelay(operation, variables) {
    const env = process.env.REACT_APP_SECURECOM_ENV;
    const state = store.getState();
    const activeSystem = selectActiveSystem(state);

    const options = {
      env,
      activeSystem,
    };

    // Main Function
    const payload = await graphqlFetchFunction(operation, variables, options);

    if (payload.errors) {
      if (payload.errors?.some((error) => error?.message === "Token Expired")) {
        dispatchLogOut(); //Relay requests at the top level of the app fail to dispatch log out, so we're intercepting them here and forcing log out so we don't get stuck in a bad token loop
      } else {
        throw new Error(JSON.stringify(payload.errors, null, 2));
      }
    }

    return JSON.parse(JSON.stringify(payload));
  };
};

export const createRelayEnvironment = () => {
  const fetchRelay = createFetchRelay();
  return new Environment({
    network: Network.create(fetchRelay),
    store: new Store(new RecordSource(), {
      gcReleaseBufferSize: 10,
    }),
  });
};

const InitRelayEnvironmentContext = React.createContext(noop);

export let relayEnvironment = createRelayEnvironment();

export const initRelayEnvironment = () => {
  relayEnvironment = createRelayEnvironment();
};

export function RelayEnvironmentProvider(props) {
  const [state, setState] = React.useState(relayEnvironment);

  React.useEffect(() => {
    setState(relayEnvironment);
  }, [relayEnvironment]);

  return (
    <DefaultRelayEnvironmentProvider environment={state}>
      {props.children}
    </DefaultRelayEnvironmentProvider>
  );
}

export const useInitRelayEnvironment = () =>
  React.useContext(InitRelayEnvironmentContext);
