/**
 * @generated SignedSource<<1e3dc78195a13e0ac48c8b93a138d371>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
export type AreaArmedStatusEnum = "ARMED" | "ARMING" | "DISARMED" | "DISARMING" | "PENDING" | "UNKNOWN";
export type BadZonesOption = "BYPASS" | "FORCE" | "REFUSE";
import { FragmentRefs } from "relay-runtime";
export type areaModel_areaConnection$data = {
  readonly edges: ReadonlyArray<{
    readonly node: {
      readonly armedStatus: AreaArmedStatusEnum;
      readonly autoArmEnabled: boolean;
      readonly autoDisarmEnabled: boolean;
      readonly badZonesOption: BadZonesOption;
      readonly id: string;
      readonly isArmable: boolean;
      readonly isDisarmable: boolean;
      readonly isInAlarm: boolean;
      readonly name: string;
      readonly number: string;
    } | null;
  }>;
  readonly " $fragmentType": "areaModel_areaConnection";
};
export type areaModel_areaConnection$key = {
  readonly " $data"?: areaModel_areaConnection$data;
  readonly " $fragmentSpreads": FragmentRefs<"areaModel_areaConnection">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "areaModel_areaConnection"
};

(node as any).hash = "3ad8b929140faba092de3413dc4ab6e1";

export default node;
